html {
  overflow-x: hidden; /* apply this to avoid extending horizontal scrollbar when dropdown menu is open */
}
:root{
  --sea-header-height: 92px;
}

#root {
  height: calc(100vh - var(--sea-header-height));
  display: block;
  width: 100%;
  table-layout: fixed;
  overflow: auto;
  position: relative;
}

.pinned--open > #root {
  height: calc(100vh - 93px);
}

.grid-vessel-header {
  display: flex;
  flex-direction: column;
}

.grid-vessel-header-details {
  font-size: 12px;
  line-height: 15px;
  color: #6A6C6D;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-vessel-header-detail {
  vertical-align: middle;
}

.grid-vessel-header-details-separator {
  width: 4px;
  height: 4px;
  line-height: 4px;
  background-color: #BCBDBE;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin: 0 6px;
  vertical-align: middle;
}